<template>
  <AppMainWindowContainer type="middle">
    <template #header>
      <div class="admin-confirm__header">
        <h1 class="header-text">
          Документы для ознакомления
        </h1>
      </div>
    </template>
    <template #center>
      <div class="doc__list">
        <div
          v-for="file in files"
          :key="file.text"
          class="doc__list__item"
        >
          <IconFileDoc />
          <span class="doc__list__item__text">
            {{ file.text }}
          </span>
        </div>
      </div>

      <div class="doc__event">
        <span class="doc__event__item">Скачать архив</span>
        <span class="doc__event__item">Отправить на почту</span>
      </div>

      <div
        v-if="canAcceptTerms"
        class="doc__text-confirm"
      >
        Нажимая «Согласится с оффертой»
        <br>
        подтверждаете согласие с нашими условиями
      </div>

      <div class="doc__button-wrapper">
        <AppLoader
          v-if="isLoading"
          style="margin: 10px"
        />

        <AppButton
          v-if="!isLoading"
          style="margin: 0 auto"
          @click.native="$router.go(-1)"
        >
          Назад
        </AppButton>
        <AppButton
          v-if="!isLoading && currentUser && canAcceptTerms"
          style="margin: 0 auto"
          @click.native="confirm()"
        >
          Согласиться с оффертой
        </AppButton>
      </div>
    </template>
  </AppMainWindowContainer>
</template>

<script>
import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import IconFileDoc from '@/components/common/icons/IconFileDoc'
import AppButton from '@/components/common/simple/AppButton'
import AppLoader from '@/components/AppLoader'
import userUtil from '@/utils/user-util'
import routeList from '@/router/labels'

export default {
  name: 'PageAdminConfirm',
  components: { AppButton, IconFileDoc, AppMainWindowContainer, AppLoader },
  data () {
    return {
      isLoading: false,
      files: [
        {
          text: ' Договор присоединения с Лицензиатом ПО для поиска сотрудников',
          filePath: ''
        },
        {
          text: 'Соглашение по тарифам',
          filePath: ''
        },
        {
          text: 'Договор оказания услуг по предоставлению персонала с Компанией по предоставлен...',
          filePath: ''
        },
        {
          text: 'Соглашение об обработке персональных данных',
          filePath: ''
        },
      ]
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.users.info
    },
    canAcceptTerms () {
      return this.currentUser && !userUtil.hasAcceptTerms(this.currentUser)
    }
  },
  methods: {
    confirm () {
      this.isLoading = true
      this.$store.dispatch('users/acceptTermsAndCondition')
        .then(() => {
          this.$store.dispatch('organization/fetchOrganizationUser', {}, { root: true })
          this.$router.push({ name: routeList.OFFICE_LIST_ROUTE_NAME })
          this.isLoading = false
        }).catch(() => {
        // TODO отображать ошибку
          this.isLoading = false
        })
    },
  }
}
</script>

<style lang="sass" scoped>
  .admin-confirm

    &__header
      text-align: center
      margin-bottom: 30px

  .doc__list
    display: flex
    justify-content: center
    &__item
      display: flex
      flex-direction: column
      align-items: center
      width: 20%
      margin: 0 auto
      cursor: pointer
      &__text
        @include fontStyle(normal, 10px)
        color: rgba(0, 0, 0, 0.8)
        margin-top: 10px
  .doc__event
    display: flex
    justify-content: center
    margin: 50px 80px 20px 80px
    &__item
      @include fontStyle(800, 14px)
      display: flex
      flex-direction: column
      align-items: center
      width: 50%
      margin: 0 auto
      cursor: pointer
      color: rgba(0, 0, 0, 0.7)

  .doc__text-confirm
    @include fontStyle(500, 14px)
    text-align: center
    width: 50%
    margin: 50px auto 30px auto

  .doc__button-wrapper
    display: flex

</style>
